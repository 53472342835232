// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js";
import { off } from "process";
var controller = new ScrollMagic.Controller();

window.addEventListener("load", () => {
  const targets = document.querySelectorAll(".js-animation-section");
  const bottom = document.querySelector("footer").getBoundingClientRect().bottom;
  Array.from(targets).forEach((element, index, array) => {
    let offset;
    offset = 50 - window.innerHeight;
    const elementTop = element.getBoundingClientRect().top;

    const isNegativeNumber = num => num < 0;
  
    if(isNegativeNumber(elementTop + offset)){
        offset = 50 - elementTop;
    }

    if(window.outerHeight >= bottom) {
      offset = -elementTop;
    }

    var scene_hero = new ScrollMagic.Scene({
      triggerElement: element,
      triggerHook: "onLeave",
      // duration: 800,
      offset,
    })
      // .addIndicators()
      .addTo(controller);

    scene_hero.on("enter", () => {
      //triggerElementを過ぎたとき
      element.classList.add("active");
    });
    scene_hero.on("leave", () => {
      //triggerElementを抜けたとき
    });
  });
});
