//開発版
// import Vue from "vue/dist/vue.esm.js";
//本番用
import Vue from "vue/dist/vue.min.js";

const form = document.querySelector(".p-contact-form");

if (form) {
  new Vue({
    el: ".p-contact",
    data: {
      type: "業務について",
      options: [
        { text: "業務について", value: "業務について" },
        { text: "採用について", value: "採用について" },
        { text: "その他", value: "その他" },
      ],
      detail: "",
      address: "",
      name: "",
      email: "",
      email2: "",
      tel: "",
      sendflg:false,
      error_words: {
        template: "入力内容に間違いがあります。",
      },
      errors: {
        detail: "",
        address: "",
        name: "",
        email: "",
        tel: "",
      },
      isValidation: true,
    },
    methods: {
      checkForm(value) {
        if (value === "") {
          return true;
        }

        return false;
      },

      validateDetail() {
        const ret = this.checkForm(this.detail);
        if (ret) {
          this.errors.detail = this.error_words.template;
        } else {
          this.errors.detail = "";
        }

        return ret;
      },
      validateName() {
        const ret = this.checkForm(this.name);
        if (ret) {
          this.errors.name = this.error_words.template;
        } else {
          this.errors.name = "";
        }

        return ret;
      },
      validateAddress() {
        const ret = this.checkForm(this.address);
        if (ret) {
          this.errors.address = this.error_words.template;
        } else {
          this.errors.address = "";
        }

        return ret;
      },
      validateEmail() {
        const regex =
          /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        const ret1 = this.checkForm(this.email);
        const ret2 = this.checkForm(this.email2);

        this.errors.email = "";

        if (ret1 && ret2) {
          this.errors.email = this.error_words.template;
        }

        if (!ret1 && !ret2) {
          regex.test(this.email);
          if (
            [this.email, this.email2].every((e) => {
              return regex.test(e);
            })
          ) {
            if (this.email !== this.email2) {
              this.errors.email = this.error_words.template;
            }
          } else {
            this.errors.email = this.error_words.template;
          }
        }

        return true;
      },

      confirmEmail() {
        const regex =
          /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        const ret1 = this.checkForm(this.email) || !regex.test(this.email);
        const ret2 = this.checkForm(this.email2) || !regex.test(this.email2);

        if (ret1 || ret2 || !(this.email === this.email2)) {
          this.errors.email = this.error_words.template;
          return true;
        } else {
          this.errors.email = "";
          return false;
        }
      },

      validateTel() {
        const regex = /^0[-0-9]{9,12}$/;
        const ret = this.checkForm(this.tel) || !regex.test(this.tel);
        if (ret) {
          this.errors.tel = this.error_words.template;
        } else {
          this.errors.tel = "";
        }
        return ret;
      },

      checkValidation() {
        const result = [
          this.validateDetail,
          this.validateAddress,
          this.validateName,
          this.confirmEmail,
          this.validateTel,
        ]
          .map((f) => f())
          .some((b) => b);

        if (result) {
          const top = document
            .querySelector(".p-contact")
            .getBoundingClientRect().top;
          window.scrollTo(0, top);
        } else {
          this.isValidation = false;
        }
      },
    },
    watch: {
      detail() {
        this.validateDetail();
      },

      name() {
        this.validateName();
      },

      address() {
        this.validateAddress();
      },

      email() {
        this.validateEmail();
      },

      email2() {
        this.validateEmail();
      },

      tel() {
        this.validateTel();
      },
      isValidation() {
        const top = document
          .querySelector(".p-contact")
          .getBoundingClientRect().top;
        window.scrollTo(0, top);
      },
    },
    created() {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("q") && searchParams.get("q") == "recruit") {
        this.type = "採用について";
      }
    },
  });
}
