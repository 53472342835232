import swiper from "swiper";

window.addEventListener("load", () => {
  $(".p-hero-loader_line_form").addClass("slide_on");
});

if ($(".js-hero-slider").length) {
  var slider = new swiper(".js-hero-slider", {
    slidesPerView: "auto",
    loop: true,
    speed: 1500,

    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    effect: "fade",
    fade: {
      crossFade: true,
    },
    on: {
      init: function () {},
      slideChange: function () {
        //テキスト切り替え
        $(".p-hero-loader-texts .p-hero-loader-texts__item").removeClass(
          "slide_on"
        );
        $(".p-hero-loader-texts .p-hero-loader-texts__item").addClass(
          "slide_off"
        );
        $(".p-hero-loader-texts .p-hero-loader-texts__item")
          .eq(this.realIndex)
          .removeClass("slide_off");
        $(".p-hero-loader-texts .p-hero-loader-texts__item")
          .eq(this.realIndex)
          .addClass("slide_on");

        ///番号切り替え
        $(".p-hero-loader-numbers .p-hero-loader-numbers__item").removeClass(
          "slide_on"
        );
        $(".p-hero-loader-numbers .p-hero-loader-numbers__item").addClass(
          "slide_off"
        );
        $(".p-hero-loader-numbers .p-hero-loader-numbers__item")
          .eq(this.realIndex)
          .removeClass("slide_off");
        $(".p-hero-loader-numbers .p-hero-loader-numbers__item")
          .eq(this.realIndex)
          .addClass("slide_on");

        //ローダー切り替え

        if ($(".p-hero-loader_line_form").hasClass("slide_on")) {
          $(".p-hero-loader_line_form").removeClass("slide_on");
          $(".p-hero-loader_line_form").addClass("slide_off");

          setTimeout(function () {
            $(".p-hero-loader_line_form").removeClass("slide_off");

            $(".p-hero-loader_line_form").addClass("slide_on");
          }, 1000);
        }
      },
    },
  });
}
